import { template as template_0e74b3132a6b457a9ecb902d2a3b8396 } from "@ember/template-compiler";
const FKText = template_0e74b3132a6b457a9ecb902d2a3b8396(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
